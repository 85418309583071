<template>
  <v-container class="welcome-discounts">
    <ebsn-meta
      class="description"
      :target="category"
      path="category_info.DESCRIPTION"
      tag="div"
    ></ebsn-meta>

    <h4 class="text-uppercase default--text">
      {{ $t("coupons.welcomeDiscounts.title") }}
    </h4>

    <div class="default--text font-weight-regular text-body-0">
      {{ $t("coupons.welcomeDiscounts.yourExpense") }}
      <strong>{{ points }}€.</strong>
    </div>

    <ExpenseProgressBar
      v-if="points != null"
      :points="points"
      :segments="segments"
    />

    <v-row>
      <v-col
        cols="12"
        sm="6"
        v-for="(discount, index) in activeDiscountCoupons"
        :key="'active-discount-' + index"
        class="coupon-col welcome-style"
      >
        <WelcomeCouponCard
          :coupon="discount"
          @showDetails="showActiveCoupon"
          @reload="reload"
          :type="'activeCashbackDiscount'"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-for="(discount, index) in discountCoupons"
        :key="'discount-' + index"
        class="coupon-col welcome-style"
      >
        <WelcomeCouponCard
          :coupon="discount"
          @showDetails="showDetails"
          @reload="reload"
          :type="'incomingDiscount'"
          :userPoints="points"
        />
      </v-col>
      <v-col
        ><v-btn
          color="primary"
          x-large
          rounded
          block
          depressed
          :to="'/coupon/ricaricati-di-buoni-info'"
          >{{ $t("coupons.welcomeDiscounts.moreInfo") }}</v-btn
        ></v-col
      >
    </v-row>
  </v-container>
</template>
<style lang="scss">
.welcome-discounts {
  padding: 10px 10px;
  .activeDiscount {
    background-color: $white !important;
  }
  .v-card--disabled {
    background-color: var(--v-white-darken1) !important;
  }
}
</style>
<script>
import ExpenseProgressBar from "@/components/coupon/ExpenseProgressBar.vue";
import CopuonService from "@/service/couponsService";
import WelcomeCouponCard from "@/components/coupon/WelcomeCouponCard.vue";
import CouponDetail from "@/components/coupon/CouponDetail.vue";
import ActiveCouponDetail from "@/components/coupon/ActiveCouponDetail.vue";
import VoucherDetail from "@/components/coupon/VoucherDetail.vue";
import categoryMixins from "~/mixins/category";
export default {
  components: { ExpenseProgressBar, WelcomeCouponCard },
  name: "LaunchVouchers",
  mixins: [categoryMixins],
  data() {
    return {
      segments: [],
      points: null,
      discountCoupons: [],
      activeDiscountCoupons: [],
      loading: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 0
      },
      tab: "discounts"
    };
  },
  computed: {},
  methods: {
    async reload() {
      await this.getProgressBar();
      await this.loadActiveDiscounts();
      await this.loadDiscounts();
    },
    async getProgressBar() {
      CopuonService.getCashbackBar().then(data => {
        this.segments = data.value.coupons;
        this.points = data.value?.userExpense;
      });
    },
    async loadDiscounts() {
      CopuonService.getIncomingDiscounts("cashback").then(data => {
        this.discountCoupons = data.values;
      });
    },
    async loadActiveDiscounts() {
      CopuonService.getActivatedDiscountsByType("cashback").then(data => {
        this.activeDiscountCoupons = data.values;
      });
    },
    async showDetails(coupon, type) {
      let res = await this.$dialog.show(CouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        couponSlug: coupon.slug,
        userPoints: this.points,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showActiveCoupon(coupon, type) {
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        customClass: "welcome-style",
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showVoucher(voucher) {
      let res = await this.$dialog.show(VoucherDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: voucher,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    }
  },
  async created() {
    await this.reload();
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  }
};
</script>
